<template>
  <div class="container">
    <div class="page-sub-box ewm">
      <div class="locker_setting_list sub_new_style01 sub_ui_box1">
        <div class="page_search_box line_bottom_1px">
          <DxValidationGroup ref="searchValidationGroup">
            <div class="flex space-x-2 inner">
              <div class="mt-2">설문기간</div>
              <DxDateBox
                :edit-enabled="false"
                :styling-mode="stylingMode"
                width="110"
                height="30"
                v-model="searchType.customTypes.dayStart"
                type="date"
                dateSerializationFormat="yyyyMMdd"
                display-format="yyyy.MM.dd"
                dateOutOfRangeMessage="종료일은 시작일보다 크거나 같아야 합니다."
                :max="searchType.customTypes.dayEnd"
                maxLength="10"
                invalid-date-message="입력 데이터가 유효하지 않습니다."
              >
                <DxValidator>
                  <DxRequiredRule message="생성일자 기간은 필수입니다." />
                </DxValidator>
              </DxDateBox>
              <div class="ui-datepicker period">
                <span class="dash">~</span>
              </div>
              <DxDateBox
                :styling-mode="stylingMode"
                width="110"
                height="30"
                v-model="searchType.customTypes.dayEnd"
                type="date"
                dateSerializationFormat="yyyyMMdd"
                display-format="yyyy.MM.dd"
                dateOutOfRangeMessage="종료일은 시작일보다 크거나 같아야 합니다."
                maxLength="10"
                :min="searchType.customTypes.dayStart"
                invalid-date-message="입력 데이터가 유효하지 않습니다."
              >
                <DxValidator>
                  <DxRequiredRule message="생성일자 기간은 필수입니다." />
                </DxValidator>
              </DxDateBox>
              <DxButton text="검색" class="btn_M box-btn-search" type="button" :height="30" @click="selectDataList" />
            </div>
          </DxValidationGroup>
        </div>
      </div>

      <div class="relative">
        <div class="absolute z-10 top-7 left-0">
          <DxButton text="복사생성" class="btn_XS default filled" type="button" :height="30" :width="75" @click="onCopyData()" />
          <DxButton text="추가" class="btn_XS default filled add1" type="button" :height="30" :width="60" @click="onAddData()" />
          <DxButton
            :text="changeButton.bText"
            :disabled="changeButton.bDisabled"
            class="btn_XS white light_filled"
            type="button"
            :height="30"
            :width="60"
            @click="onChangeStep()"
            ref="changeButton"
          />
          <DxButton text="삭제" class="btn_XS white light_filled trash" type="button" :height="30" :width="60" @click="onDeleteData()" />
        </div>

        <esp-dx-data-grid :data-grid="dataGrid" ref="surveysGrid" @selection-changed="onSelectionChanged" @row-click="onRowClick" />
      </div>
    </div>
  </div>
</template>

<script>
  import { DxDateBox } from 'devextreme-vue/date-box';
  import { DxButton } from 'devextreme-vue/button';
  import { getPastFromToday, isSuccess } from '@/plugins/common-lib';
  import { DxValidator, DxRequiredRule } from 'devextreme-vue/validator';
  import { DxValidationGroup } from 'devextreme-vue/validation-group';
  import CustomStore from 'devextreme/data/custom_store';
  import EspDxDataGrid from '@/components/devextreme/esp-dx-data-grid.vue';

  let vm = this;

  export default {
    components: {
      EspDxDataGrid,
      DxDateBox,
      DxButton,
      DxRequiredRule,
      DxValidator,
      DxValidationGroup,
    },
    props: {},
    watch: {},
    data() {
      return {
        stylingMode: 'outlined', //outlined, underlined, filled
        searchType: {
          obj: {},
          defaultObj: { id: 'ROOT', codeValue: 'ROOT', codeNm: '전체' },
          defaultValue: 'ROOT',
          customTypes: {
            dayStart: getPastFromToday(3, 'months'),
            dayEnd: getPastFromToday(-2, 'months'),
          },
          paramsData: null,
        },
        changeButton: {
          bText: '시작',
          bDisabled: true,
        },
        codes: {
          surProcessCd: {
            dataSource: [],
            displayExpr: 'codeNm',
            valueExpr: 'codeId',
          },
        },
        selectedRowsData: [], //선택한(체크된) 로우 데이터
        dataGrid: {
          keyExpr: 'id',
          refName: 'surveysGrid',
          showMenuColumns: true, //컬럼 표시
          allowColumnResizing: true, //컬럼 사이즈 허용
          columnResizingMode: 'widget',
          columnAutoWidth: true,
          showBorders: false, //border 유무
          showColumnHeaders: true, //컬럼 헤더 유무
          showColumnLines: false, //컬럼 세로선 유무
          showRowLines: true, //컬럼 가로선 유무
          rowAlternationEnabled: false, //행 배경색
          hoverStateEnabled: true, //마우스커서 행 강조
          dataSource: [],
          // width:'200',     // 주석처리시 100%
          height: 'calc(100vh - 370px)', // 주석처리시 100%
          showActionButtons: {
            customButtons: [
              // {
              //     widget: 'dxButton',
              //     options: {
              //         icon: '',
              //         text:'복사생성',
              //         elementAttr: {class : 'btn_XS default filled'},
              //         width: 75,
              //         height: 30,
              //         onClick() {
              //             vm.onCopyData();
              //         }
              //     },
              //     location: 'before'
              // },
              // {
              //     widget: 'dxButton',
              //     options: {
              //         icon: '',
              //         text:'추가',
              //         elementAttr: {class : 'btn_XS default filled add1'},
              //         width: 60,
              //         height: 30,
              //         onClick() {
              //             vm.onAddData();
              //         }
              //     },
              //     location: 'before'
              // },
              // {
              //     widget: 'dxButton',
              //     options: {
              //         icon: '',
              //         text: '시작',
              //         elementAttr: {class : 'btn_XS white light_filled'},
              //         width: 60,
              //         height: 30,
              //         onClick() {
              //             vm.onChangeStep();
              //         }
              //     },
              //     location: 'before'
              // },
              // {
              //     widget: 'dxButton',
              //     options: {
              //         icon: '',
              //         text: '삭제',
              //         elementAttr: { class: 'btn_XS white light_filled trash' },
              //         width: 60,
              //         height: 30,
              //         onClick() {
              //             vm.onDeleteData();
              //         },
              //     },
              //     location: 'before',
              // },
            ],
          },
          customEvent: {
            selectionChanged: true,
            rowClick: true,
          },
          callApi: 'CALL_EWM_API',
          grouping: {
            contextMenuEnabled: false,
            autoExpandAll: false,
            allowCollapsing: true,
            expandMode: 'rowClick', // rowClick or buttonClick
          },
          groupPanel: {
            visible: false,
          },
          columnChooser: {
            enabled: false, // 컬럼 Chooser 버튼 사용유무
          },
          loadPanel: {
            enabled: true, // 로딩바 표시 유무
          },
          scrolling: {
            // 미사용시 주석처리
            mode: 'standard', //스크롤 모드 : ['infinite', 'standard', 'virtual']
          },
          remoteOperations: {
            filtering: false,
            sorting: false,
            grouping: false,
            paging: false,
          },
          paging: {
            // scrolling 미사용시만 적용됨
            enabled: false,
            pageSize: 10,
            pageIndex: 0, // 시작페이지
          },
          pager: {
            visible: false, //페이저 표시 여부
            showPageSizeSelector: false, //페이지 사이즈 선택버튼 표시 여부
            allowedPageSizes: [5, 10, 15, 20], //페이지 사이즈 선택 박스
            displayMode: 'compact', //표시 모드 : ['full', 'compact']
            showInfo: true, //페이지 정보 표시 여부 : full인 경우만 사용 가능
            showNavigationButtons: true, //페이지 네비게이션(화살표) 버튼 표시 여부 : full인 경우만 사용 가능
          },
          filterRow: {
            visible: true,
            operationDescriptions: {
              contains: '포함',
            },
          },
          headerFilter: {
            visible: true,
          },
          editing: {
            allowUpdating: false,
            allowDeleting: false,
            allowAdding: false,
            mode: 'batch', //수정 모드: ['row', 'cell', 'batch']
            startEditAction: 'click', //셀 편집 상태로 변경 할 이벤트 타입 : ['click', 'dbclick'] / 'cell', 'batch' 모드인 경우에만 가능
            selectTextOnEditStart: true, //셀 수정시 텍스트 전체 선택 여부
          },
          selecting: {
            mode: 'multiple', //행 단일/멀티 선택 타입 : ['single', 'multiple']
            selectAllMode: 'allPages', //행 선택 허용 범위 : ['allPages', 'page']
            showCheckBoxesMode: 'always', //행 선택 모드 : ['none', 'onClick', 'onLongTap', 'always']
          },
          columns: [
            {
              caption: '설문제목',
              dataField: 'surNm',
              visible: true,
              allowEditing: false,
              allowHeaderFiltering: false,
              allowSorting: true,
              cellTemplate: (container, options) => {
                //if(options.data.surProcessCd === 1099 || options.data.surProcessCd === 1112){
                let aTag = document.createElement('a');
                aTag.innerText = options.value;
                aTag.addEventListener('click', () => {
                  vm.onUpdateData(options.data.id);
                });
                container.append(aTag);
                //}else{
                //    container.innerText = options.value;
                //}
              },
            },
            {
              caption: '참여율',
              dataField: 'rate',
              alignment: 'center',
              width: 140,
              visible: true,
              allowEditing: false,
              allowFiltering: true,
              allowSorting: true,
              /* cellTemplate: (container, option) => {
                            let tag = document.createElement('div');
                            let value = `${option.data.partiPerson}/${option.data.targetPerson} ( ${option.data.partiRate}% )`;
                            tag.innerHTML = value;
                            container.append(tag);
                        }, */
              calculateCellValue: rowData => {
                return `${rowData.partiPerson}/${rowData.targetPerson} ( ${rowData.partiRate}% )`;
              },
            },
            {
              caption: '진행상태',
              dataField: 'surProcessCd',
              alignment: 'center',
              width: 180,
              visible: true,
              allowEditing: false,
              allowHeaderFiltering: true,
              allowSorting: true,
              lookup: {
                dataSource: this.$_enums.ewm.surProcess.values,
                displayExpr: 'label',
                valueExpr: 'value',
              },
              cellTemplate: (container, options) => {
                const prevButton = new DxButton({
                  propsData: {
                    template: '<span class="mdi mdi-chevron-left"></span>',
                    height: 30,
                    type: 'button',
                    hint: '이전 상태로 변경',
                    elementAttr: { class: 'btn_XS white outlined' },
                    disabled: this.$_enums.ewm.surProcess.READY.value === options.data.surProcessCd,
                    onClick: () => {
                      vm.onChangeProcess(options, 'PREV');
                    },
                  },
                });

                prevButton.$mount();
                //container.append(prevButton.$el);

                const valueDivTag = document.createElement('div');
                valueDivTag.innerText = options.displayValue;
                valueDivTag.setAttribute('class', 'pt-2');

                const nextButton = new DxButton({
                  propsData: {
                    template: '<span class="mdi mdi-chevron-right"></span>',
                    height: 30,
                    type: 'button',
                    hint: '다음 상태로 변경',
                    elementAttr: { class: 'btn_XS white outlined mr-0' },
                    disabled: this.$_enums.ewm.surProcess.PROGRESS_COMPLETE.value === options.data.surProcessCd,
                    onClick: () => {
                      vm.onChangeProcess(options, 'NEXT');
                    },
                  },
                });

                nextButton.$mount();
                //container.append(nextButton.$el);

                const divTag = document.createElement('div');
                divTag.setAttribute('class', 'flex justify-between justify-items-center gap-x-2');
                divTag.appendChild(prevButton.$el);
                divTag.appendChild(valueDivTag);
                divTag.appendChild(nextButton.$el);
                container.append(divTag);
              },
            },
            {
              caption: '결과보기',
              dataField: 'viewResult',
              alignment: 'center',
              width: 130,
              visible: true,
              allowEditing: false,
              allowHeaderFiltering: false,
              allowFiltering: false,
              allowSorting: false,
              cellTemplate: (container, options) => {
                //if(options.data.surProcessCd === 1100 || options.data.surProcessCd === 1101){
                let button = new DxButton({
                  propsData: {
                    text: '',
                    icon: 'search',
                    elementAttr: { class: 'white light_filled txt_S' },
                    value: options.value,
                    disabled:
                      options.data.surProcessCd !== this.$_enums.ewm.surProcess.PROGRESS.value &&
                      options.data.surProcessCd !== this.$_enums.ewm.surProcess.PROGRESS_COMPLETE.value,
                    onClick: () => {
                      vm.viewResult(options.data);
                    },
                  },
                });
                button.$mount();
                container.append(button.$el);
                /* }else{
                                container.innerHTML = "-";
                            } */
                //테스트 용으로 풀어놓음
              },
            },
            {
              caption: '설문기간',
              dataField: 'surDt',
              alignment: 'center',
              width: 190,
              visible: true,
              allowEditing: false,
              allowHeaderFiltering: false,
              allowFiltering: true,
              allowSorting: true,
              /* cellTemplate: (container, option) => {
                            let tag = document.createElement('div');
                            let value = `${vm.surFormatDt(option.data.surStartDt)} ~ ${vm.surFormatDt(option.data.surEndDt)}`;
                            tag.innerHTML = value;
                            container.append(tag);
                        }, */
              calculateCellValue: rowData => {
                return `${vm.surFormatDt(rowData.surStartDt)} ~ ${vm.surFormatDt(rowData.surEndDt)}`;
              },
            },
            {
              caption: '작성자',
              dataField: 'regNm',
              alignment: 'center',
              width: 140,
              visible: true,
              allowEditing: false,
              allowHeaderFiltering: true,
              allowSorting: true,
              calculateCellValue: rowData => {
                return `${rowData.regNm} [${rowData.regId}]`;
              },
              /* cellTemplate: (container, option) => {
                            let tag = document.createElement('div');
                            let value = `${option.data.regNm} [${option.data.regId}]`;
                            tag.innerHTML = value;
                            container.append(tag);
                        }, */
            },
            {
              caption: '시작',
              dataField: 'surStartDt',
              alignment: 'center',
              visible: false,
              sortOrder: 'desc',
            },
          ],
        },
      };
    },
    computed: {
      searchValidationGroup: function () {
        return this.$refs['searchValidationGroup'].instance;
      },
    },
    methods: {
      /** @description : 상태 변경 */
      async onChangeProcess(options, type) {
        const curProcessIdx = this.$_enums.ewm.surProcess.values.findIndex(element => element.value === options.row.data.surProcessCd);

        const chgProcessCdIdx = curProcessIdx + (type == 'PREV' ? -1 : 1);

        if (chgProcessCdIdx < 0 || chgProcessCdIdx >= this.$_enums.ewm.surProcess.values.length) {
          return;
        }

        if (type == 'PREV') {
          if (
            this.$_enums.ewm.surProcess.values[chgProcessCdIdx].value === this.$_enums.ewm.surProcess.PROGRESS_STOP.value &&
            !(await this.$_Confirm('설문 데이터가 초기화됩니다. 변경하시겠습니까?'))
          ) {
            return;
          }
          // if (
          // 	this.$_enums.ewm.surProcess.values[chgProcessCdIdx].value === this.$_enums.ewm.surProcess.FEEDBACK.value &&
          // 	!(await this.$_Confirm('등록한 피드백정보가 초기화됩니다. 변경하시겠습니까?'))
          // ) {
          // 	return;
          // }
        }

        const chgProcessCd = this.$_enums.ewm.surProcess.values[chgProcessCdIdx].value;
        console.log('chgProcessCd', chgProcessCd);
        const data = {
          id: options.row.data.id,
          processCd: chgProcessCd,
        };

        const payload = {
          actionname: 'EWM_SURVEY_PROCESS_UPDATE',
          data: data,
        };

        const res = await this.CALL_EWM_API(payload);
        if (isSuccess(res)) {
          this.$set(options.row.data, 'surProcessCd', chgProcessCd);
          options.component.repaintRows(options.rowIndex);
          this.$_Toast(this.$_msgContents('CMN_SUC_SAVE', { defaultValue: '정상적으로 저장되었습니다.' }));
        } else {
          this.$_Toast(this.$_msgContents('CMN_ERROR', { defaultValue: '데이터 처리 중 오류가 발생하였습니다.' }));
        }
      },
      /** @description : 상담사 클릭 이벤트  */
      onRowClick(e) {
        //this.selectedRowsData = [e.data];
        //this.$refs.surveysGrid.getGridInstance.selectedRowKeys = [e.data.id];
        this.$refs.surveysGrid.getGridInstance.selectRows([e.data.id], false);

        // FIXME : (EWM) 공통코드 하드 코딩
        /* if(e.data.surProcessCd == 1100){
                this.changeButton.bText = '중지';
                this.changeButton.bDisabled = false;
            }else if(e.data.surProcessCd == 1111){
                this.changeButton.bText = '시작';
                this.changeButton.bDisabled = false;
            }else{
                this.changeButton.bText = '시작';
                this.changeButton.bDisabled = true;
            } */
      },
      /** @description: 그리드 선택시 변경 관련 이벤트 */
      onSelectionChanged(rowData) {
        if (rowData.selectedRowsData.length < 1) {
          //선택한 값 확인
          this.selectedRowsData = [];
        } else {
          this.selectedRowsData = rowData.selectedRowsData;
        }

        const changeButton = this.$refs.changeButton; //버튼확인

        if (changeButton) {
          //초기셋팅
          this.changeButton.bText = '시작';
          this.changeButton.bDisabled = true;
        }

        if (rowData.selectedRowsData.length == 1) {
          for (const cd of rowData.selectedRowsData) {
            // FIXME : (EWM) 공통코드 하드 코딩
            if (
              cd.surProcessCd == this.$_enums.ewm.surProcess.PROGRESS_STOP.value ||
              cd.surProcessCd == this.$_enums.ewm.surProcess.READY.value
            ) {
              //중지 또는 대기 상태일 경우
              if (changeButton) {
                this.changeButton.bText = '시작';
                this.changeButton.bDisabled = false;
              }
            } else if (cd.surProcessCd == this.$_enums.ewm.surProcess.PROGRESS.value) {
              //설문진행 상태일 경우
              if (changeButton) {
                this.changeButton.bText = '중지';
                this.changeButton.bDisabled = false;
              }
            } else {
              //그 외 값
              if (changeButton) {
                this.changeButton.bText = '시작';
                this.changeButton.bDisabled = true;
              }
            }
          }
        }
      },
      /** @description: 진행상태 클릭(시작-중지) 이벤트 */
      async onChangeStep() {
        const rowsData = this.selectedRowsData; //선택된 값
        // FIXME : (EWM) 공통코드 하드 코딩
        const all1100 = rowsData.every(cd => cd.surProcessCd === this.$_enums.ewm.surProcess.PROGRESS.value); //모두 진행상태일 경우
        const all1111 = rowsData.every(cd => cd.surProcessCd === this.$_enums.ewm.surProcess.PROGRESS_STOP.value); //모두 중지상태일 경우
        const alll099 = rowsData.every(cd => cd.surProcessCd === this.$_enums.ewm.surProcess.READY.value); //모두 대기상태일 경우

        if (all1111 || alll099) {
          //중지 상태일 경우
          if (
            await this.$_Confirm('시작 하시겠습니까?', {
              title: `<h2>알림</h2>`,
            })
          ) {
            const sendData = {
              //보낼 데이터
              id: rowsData[0].id,
              processCd: 1100,
            };

            const payload = {
              actionname: 'EWM_SURVEY_PROCESS_UPDATE',
              data: sendData,
              loading: true,
            };

            const res = await this.CALL_EWM_API(payload);

            if (isSuccess(res)) {
              this.$_Msg('시작 하였습니다.<br>설문조사가 진행됩니다.', {
                title: `<h2>알림</h2>`,
              });
              this.refreshGridList();
            } else {
              this.$_Toast(this.$_msgContents('CMN_ERROR', { defaultValue: '데이터 처리 중 오류가 발생하였습니다.' }));
            }
          } else {
            return;
          }
        } else if (all1100) {
          //설문진행 상태일 경우
          if (
            await this.$_Confirm('진행을 중지하시겠습니까?', {
              title: `<h2>알림</h2>`,
            })
          ) {
            const sendData = {
              //보낼 데이터
              id: rowsData[0].id,
              processCd: 1111,
            };

            const payload = {
              actionname: 'EWM_SURVEY_PROCESS_UPDATE',
              data: sendData,
              loading: true,
            };

            const res = await this.CALL_EWM_API(payload);

            if (isSuccess(res)) {
              this.$_Msg('정상적으로 설문을 중지하였습니다.<br>다시 시작을 하셔야 설문진행이 가능합니다.', {
                title: `<h2>알림</h2>`,
              });
              this.refreshGridList();
            } else {
              this.$_Toast(this.$_msgContents('CMN_ERROR', { defaultValue: '데이터 처리 중 오류가 발생하였습니다.' }));
            }
          } else {
            return;
          }
        } else {
          //그 외
          this.$_Msg('진행상태가 모두 진행 또는 중지일 경우만 사용가능합니다.');
          return;
        }
      },
      /** @description: 복사생성 이벤트 */
      async onCopyData() {
        const rowsData = this.selectedRowsData; //선택된 값

        if (rowsData.length < 1) {
          this.$_Msg(this.$_msgContents('COMMON.MESSAGE.CMN_NOT_SELECTED', { defaultValue: '대상이 선택되어 있지 않습니다.' }));
          return;
        }

        if (!(await this.$_Confirm('선택한 데이터를 복사하시겠습니까?', { title: `<h2>알림</h2>` }))) {
          return;
        }

        const sendData = rowsData.map(x => x.id); //복사할 값

        const payload = {
          actionname: 'EWM_SURVEY_SCHEDULE_DUPLICATE',
          data: sendData,
          loading: true,
        };

        const res = await this.CALL_EWM_API(payload);

        if (isSuccess(res)) {
          this.$_Msg('선택된 설문이 복사되었습니다.');
          this.refreshGridList();
        } else {
          this.$_Toast(this.$_msgContents('CMN_ERROR', { defaultValue: '데이터 처리 중 오류가 발생하였습니다.' }));
        }
      },
      /** @description: 추가 페이지 이동 이벤트 */
      onAddData() {
        let params = { updateYn: false };
        this.$store.commit('setDetailParams', params);
        this.$router.push({ path: '/ewm/surveys/implementation/config' });
      },
      /** @description: 설문제목 클릭 이동 이벤트 */
      onUpdateData(id) {
        let params = { id: id, updateYn: true };
        this.$store.commit('setDetailParams', params);
        this.$router.push({ path: '/ewm/surveys/implementation/config' });
      },
      /** @description: 결과보기 클릭 이동 이벤트 */
      viewResult(data) {
        let params = {
          id: data.id,
        };
        this.$store.commit('setDetailParams', params);
        this.$router.push({ path: '/ewm/surveys/implementation/detail' });
      },
      /** @description: 삭제 클릭 이벤트 */
      async onDeleteData() {
        const rowsData = this.selectedRowsData; //선택된 값

        if (rowsData.length < 1) {
          this.$_Msg(this.$_msgContents('COMMON.MESSAGE.CMN_NOT_SELECTED', { defaultValue: '대상이 선택되어 있지 않습니다.' }));
          return;
        }

        const allowDelete = rowsData.every(
          cd =>
            cd.surProcessCd == this.$_enums.ewm.surProcess.PROGRESS_TEMP.value ||
            cd.surProcessCd == this.$_enums.ewm.surProcess.READY.value ||
            cd.surProcessCd == this.$_enums.ewm.surProcess.PROGRESS_STOP.value,
        );

        if (!allowDelete) {
          this.$_Msg(`사용된 설문조사가 있습니다. 진행상태를 확인하세요.`);
          return;
        }

        if (!(await this.$_Confirm(this.$_msgContents('CMN_CFM_DELETE_SELECTED'), { title: `<h2>알림</h2>` }))) {
          return;
        }

        const sendData = rowsData.map(x => x.id);

        const payload = {
          actionname: 'EWM_SURVEY_SCHEDULE_DELETE',
          data: sendData,
          loading: true,
        };

        const res = await this.CALL_EWM_API(payload);

        if (isSuccess(res)) {
          this.$_Toast(this.$_msgContents('CMN_SUC_DELETE'));
          this.refreshGridList();
        } else {
          this.$_Toast(this.$_msgContents('CMN_ERROR', { defaultValue: '데이터 처리 중 오류가 발생하였습니다.' }));
        }
      },
      /** @description: grid refresh 이벤트 */
      refreshGridList() {
        this.$refs.surveysGrid.getGridInstance.refresh();
        this.$refs.surveysGrid.getGridInstance.deselectAll(); //선택 해제
      },
      onChangeSearchDay() {
        const regDt = this.searchType.customTypes.dayStart + '000000' + '~' + this.searchType.customTypes.dayEnd + '235959';
        this.searchType.paramsData = { ...this.searchType.paramsData, regDt };

        this.$_setSearchHistsCustomType(this.searchType.paramsData);
      },
      /** @description : 날짜 형식 변환 메서드 */
      formatDt(rowData) {
        if (rowData?.surDt) {
          return this.$_commonlib.formatDate(rowData.surDt, 'YYYYMMDDHHmmssSSS', 'YYYY.MM.DD HH:mm:ss');
        }
      },
      /** @description : 설문 기간 날짜 형식 변환 메서드 */
      surFormatDt(date) {
        return this.$_commonlib.formatDate(date, 'YYYYMMDD', 'YYYY.MM.DD');
      },
      /** @description : 데이터 검색 메서드 */
      async selectDataList(sort = '-id') {
        /* if(!vm.searchType.customTypes.dayStart || !vm.searchType.customTypes.dayEnd){
                return;
            } */

        if (!vm.searchValidationGroup.validate().isValid) {
          return;
        }

        this.dataGrid.dataSource = new CustomStore({
          key: 'id',
          async load(loadOptions) {
            let params = vm.$_getDxDataGridParam(loadOptions);
            if (!params.sort) {
              params.sort = sort;
            }

            vm.searchType.paramsData['frSurDt'] = vm.searchType.customTypes.dayStart;
            vm.searchType.paramsData['toSurDt'] = vm.searchType.customTypes.dayEnd;

            params = { ...params, ...vm.searchType.paramsData };

            const payload = {
              actionname: 'EWM_SURVEY_SCHEDULE_LIST',
              data: params,
              loading: false,
              useErrorPopup: true,
            };

            const res = await vm.CALL_EWM_API(payload);

            if (isSuccess(res)) {
              const rtnData = {
                data: res.data.data,
                totalCount: res.data.header.totalCount,
              };
              vm.$refs.surveysGrid.totalCount = rtnData.totalCount;
              return rtnData;
            } else {
              return () => {
                throw 'Data Loading Error';
              };
            }
          },
        });
      },
      /** @description : 셀렉트 박스 초기화 */
      async initCodeMap() {
        this.codeMap = await this.$_getCodeMapList('root_ewm_sur_process');
      },
      /** @description : 라이프사이클 created시 호출되는 메서드 */
      createdData() {
        vm = this;

        this.initCodeMap().then(() => {
          this.codes.surProcessCd.dataSource = this.$_fetchCodesByDepth(this.codeMap['root_ewm_sur_process'], 2);

          //this.$refs.surveysGrid.getGridInstance.columnOption('surProcessCd', 'lookup', this.codes.surProcessCd);
        });
      },
      /** @description : 라이프사이클 mounted시 호출되는 메서드 */
      mountData() {
        this.searchType.paramsData = null;
        this.onChangeSearchDay();

        this.selectDataList();
      },
    },
    created() {
      this.createdData();
    },
    mounted() {
      this.mountData();
    },
  };
</script>

<style scoped>
  .sub_new_style01 .page_search_box .inner div {
    display: inline-block;
  }

  .sub_new_style01 .page_search_box .inner > div {
    vertical-align: middle;
    margin-right: 10px;
  }

  .page_search_box .inner .box-btn-search {
    margin-right: 15px;
  }
</style>
